import React, { useEffect, useState } from 'react';
import logo from '../../assets/icons/Armorylogo.svg';
import Button from '../ui/Button.tsx';
import useFlickerEffect from '../../hooks/useFlickerEffect.ts';

const Header = () => {

    const activeClass = useFlickerEffect();
    const [sidebar, setSidebar] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleSidebar = () => {
        setSidebar((prev) => !prev);
    };

    // Disable body scroll when sidebar is open
    useEffect(() => {
        if (sidebar) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.style.overflow = "auto"; // Cleanup on unmount
        };
    }, [sidebar]);

    // Handle scroll state
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);



    return (
        <>
            <header className={` fixed z-[100] top-0 left-0 right-0 w-full transition-all duration-200   ${isScrolled && 'bg-black/70 '} `}>
                <div className={`before:absolute before:top-0  before:left-0 before:bg-[url('../src/assets/images/bgeffect1.png')] ${activeClass && 'before:animate-flicker'} before:bg-no-repeat before:max-w-[500px]  before:w-full before:h-[500px] ${isScrolled && 'before:hidden'}`}>
                    <div className="container flex justify-between relative z-20  xl:gap-[50px] items-center pt-[31px] lg:pt-[32px] pb-[16px]">
                        <a href="#banner">
                            <img className="max-w-[135px]" src={logo} alt="logo" />
                        </a>
                        {/* Hamburger Icon for Mobile */}
                        <div
                            onClick={toggleSidebar}
                            className="text-white lg:hidden cursor-pointer"
                        >
                            <span className="block w-8 h-[2px] bg-white mb-1"></span>
                            <span className="block w-8 h-[2px] bg-white mb-1"></span>
                            <span className="block w-8 h-[2px] bg-white"></span>
                        </div>
                        {/* Sidebar */}
                        <div
                            className={`fixed top-0 ${sidebar ? 'right-0' : '-right-full'
                                } h-full md:w-[50%] w-[75%] bg-black text-white transition-all duration-500 lg:hidden z-50`}
                        >
                            <div className="p-6">
                                <button
                                    onClick={toggleSidebar}
                                    className="text-white absolute top-4 right-4 text-2xl"
                                >
                                    &times;
                                </button>
                                <nav>
                                    <ul className="flex flex-col gap-6 mt-10">
                                        <li className="font-gothicproLight text-white cursor-pointer hover:text-gray-300">
                                            <a href="#Platform">
                                                Platform
                                            </a>
                                        </li>
                                        <li className="font-gothicproLight text-white cursor-pointer hover:text-gray-300">
                                            <a href="#WarRooms">
                                                War Rooms
                                            </a>
                                        </li>
                                        <li className="font-gothicproLight text-white cursor-pointer hover:text-gray-300">
                                            <a href="#EBC">
                                                EBC
                                            </a>
                                        </li>

                                        <li className="font-gothicproLight text-white cursor-pointer hover:text-gray-300">
                                            <a href="#strategysection">
                                                Strategy
                                            </a>
                                        </li>
                                        <li className="font-gothicproLight text-white cursor-pointer hover:text-gray-300">
                                            <a href="#customerfeedback">
                                                Feedbacks
                                            </a>
                                        </li>
                                        <li className="font-gothicproLight text-white cursor-pointer hover:text-gray-300">
                                            <a href="#aboutus">
                                                About Us
                                            </a>
                                        </li>
                                        <li className="font-gothicproLight text-white cursor-pointer hover:text-gray-300">
                                            <a href="#careers">
                                                Careers
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                                <div className="flex flex-col gap-4 mt-10">
                                    <a href="#contactsection">
                                        <Button varient="solid" buttonClass="px-5 w-full">
                                            <span className="whitespace-nowrap !leading-[16px]">Book a demo</span>
                                        </Button>
                                    </a>
                                    <Button href='https://app.armorydefense.com/' varient="liquid" buttonClass=" px-[19px] w-full">
                                        <span className="whitespace-nowrap !leading-[16px]">Log in</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {/* Desktop Navigation */}
                        <div className="hidden lg:flex items-center justify-between gap-4 xl:gap-[50px]">
                            <nav>
                                <ul className="flex justify-between gap-3 xl:gap-[30px]">
                                    <li>
                                        <a className='  text-[13px] xl:text-base whitespace-nowrap font-gothicproLight leading-[16.75px] text-white cursor-pointer duration-[0.5s]  hover:drop-shadow-custom backdrop-blur-[2px] hover:text_shadow HeaderNavAnimation' href="#Platform">
                                            Platform
                                        </a>
                                    </li>
                                    <li>
                                        <a className='  text-[13px] xl:text-base whitespace-nowrap font-gothicproLight leading-[16.75px] text-white cursor-pointer duration-[0.5s]  hover:drop-shadow-custom backdrop-blur-[2px] hover:text_shadow HeaderNavAnimation' href="#WarRooms">
                                            War Rooms
                                        </a>
                                    </li>
                                    <li>
                                        <a className='  text-[13px] xl:text-base whitespace-nowrap font-gothicproLight   leading-[16.75px] text-white cursor-pointer duration-[0.5s]  hover:drop-shadow-custom backdrop-blur-[2px] hover:text_shadow HeaderNavAnimation' href="#EBC">
                                            EBC
                                        </a>
                                    </li>
                                    <li>
                                        <a className='  text-[13px] xl:text-base whitespace-nowrap font-gothicproLight leading-[16.75px] text-white cursor-pointer duration-[0.5s]  hover:drop-shadow-custom backdrop-blur-[2px] hover:text_shadow HeaderNavAnimation' href="#strategysection">
                                            Strategy
                                        </a>
                                    </li>
                                    <li>
                                        <a className='  text-[13px] xl:text-base whitespace-nowrap font-gothicproLight leading-[16.75px] text-white cursor-pointer duration-[0.5s]  hover:drop-shadow-custom backdrop-blur-[2px] hover:text_shadow HeaderNavAnimation' href="#customerfeedback">
                                            Feedbacks
                                        </a>
                                    </li>
                                    <li>
                                        <a className='  text-[13px] xl:text-base whitespace-nowrap font-gothicproLight leading-[16.75px] text-white cursor-pointer duration-[0.5s]  hover:drop-shadow-custom backdrop-blur-[2px] hover:text_shadow HeaderNavAnimation' href="#aboutus">
                                            About Us
                                        </a>
                                    </li>
                                    <li>
                                        <a className='  text-[13px] xl:text-base whitespace-nowrap font-gothicproLight leading-[16.75px] text-white cursor-pointer duration-[0.5s]  hover:drop-shadow-custom backdrop-blur-[2px] hover:text_shadow HeaderNavAnimation' href="#careers">
                                            Careers
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <div className="flex gap-4 items-center">
                                <Button href='#contactsection' varient="solid" buttonClass="px-[30px] py-[6.5px]  font-bold text-nowrap leading-4">
                                    <span className='text-[16px]' >
                                        Book a demo
                                    </span>
                                </Button>
                                <Button href='https://app.armorydefense.com/' varient="liquid" buttonClass="px-[33px] py-[12.7px]  font-bold text-nowrap leading-4">
                                    Log in
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;