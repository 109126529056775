import React, { useState } from 'react'
import FooterLogo from "../../assets/icons/Armorylogofooter.svg"
import facebook from "../../assets/icons/facebook.svg"
import Twitter from "../../assets/icons/twitter.svg"
import Linkedin from "../../assets/icons/linkdin.svg"
import Stars from "../../assets/icons/footerdots.svg"
import Button from '../ui/Button.tsx'
import bg7 from "../../assets/images/shadowline2.png";
import { Link } from 'react-router-dom'
import useFlickerEffect from '../../hooks/useFlickerEffect.ts'
import PrivacyPolicyPopup from '../ui/PrivacyPolicyPopup.tsx'
const Footer = () => {
    const [openPopup, setOpenPopup] = useState(false);
    const activeClass = useFlickerEffect();

    const handleContactClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        const recipientEmail = "sales@armorydefense.com";
        const subject = "Request for Live Demo";
        const body = `I would like to obtain more information about Armory`;
        window.location.href = `mailto:${recipientEmail}?subject=${encodeURIComponent(
            subject
        )}&body=${encodeURIComponent(body)}`;
    };

    const handleOpenPopup = () => {
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };
    return (
        <>
            <footer className="py-[40px] relative z-20 md:mt-[120px] mt-[60px]  bg-black/50  ">
                <img className={`hidden lg:block absolute top-[-270%] ${activeClass && 'animate-flicker'}  right-0`} src={bg7} alt="flickerimg" />
                <div className="max-w-[1200px] w-full mx-auto px-5">
                    <div className="flex flex-col lg:flex-row justify-between items-start">
                        <div>
                            <a href="#banner">
                                <img src={FooterLogo} alt="footerlogo" />
                            </a>
                            <div className="flex gap-[27px] w-full  items-center mt-6">
                                {/* <a target='_blank' href="https://www.facebook.com/people/Armory-Defense/61566440380949/">
                                    <img
                                        className="cursor-pointer max-w-[21.71px] w-full footerIconsAnimation custom-glow-icons drop-shadow-custom-glow"
                                        src={facebook}
                                        alt="facebook"
                                    />
                                </a>
                                <a target='_blank' href="https://x.com/Armory_Defense">
                                    <img
                                        className="cursor-pointer max-w-[21.71px] w-full footerIconsAnimation custom-glow-icons drop-shadow-custom-glow"
                                        src={Twitter}
                                        alt="twitter"
                                    />
                                </a> */}
                                <a target='_blank' href="https://www.linkedin.com/company/armorydefense">
                                    <img
                                        className="cursor-pointer max-w-[21.71px] w-full footerIconsAnimation custom-glow-icons drop-shadow-custom-glow"
                                        src={Linkedin}
                                        alt="linkedin"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className='flex flex-col items-start lg:items-end mt-6 lg:mt-0'>
                            <p className='font-gothicproLight leading-[149%] md:text-[22px] text-base text-[#C9C9C9] ' >Enough with unnecessary findings,</p>
                            <h2 className='text-white text-2xl sm:text-3xl md:text-[50px] font-gothicproMedium sm:leading-[52px] text-wrap'>ONLY WHAT YOU  <span className='text-[#2972D7] '>NEED</span></h2>
                            <Button href='#contactsection' varient='liquid' buttonClass='mt-6 px-[19px] py-3  font-bold leading-4' >Book a demo</Button>
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row justify-between items-start md:items-end mt-20 md:mt-[87px]'>
                        <div className='animate-pulse'>
                            <img className='max-w-20 w-full' src={Stars} alt="stars" /></div>
                        <div className='max-w-[221px] w-full flex justify-between items-center mt-6 md:mt-0 font-inter'>
                            <div className="h-6  overflow-hidden group inline-block"><p onClick={handleContactClick} className="flex flex-col transition-all duration-500 group-hover:-translate-y-[24px] cursor-pointer"
                            >
                                <span
                                    className="text-white text-[14px] pb-[1px]  text-subparagraph leading-[140%]  capitalize border-b-[1px] border-white">
                                    Contact us
                                </span>
                                <span
                                    className=" capitalize transition-all duration-500 text-white text-[14px] leading-[140%]  text-subparagraph  group-hover:border-b-[1px] group-hover:border-white">
                                    Contact us
                                </span>
                            </p>
                            </div>
                            <button onClick={handleOpenPopup} className="h-6  overflow-hidden group inline-block"><p className="flex flex-col transition-all duration-500 group-hover:-translate-y-[24px] cursor-pointer"
                            >
                                <span
                                    className="text-white text-[14px] pb-[1px] text-subparagraph leading-[140%]  capitalize border-b-[1px] border-white">
                                    Privacy
                                </span>
                                <span
                                    className="capitalize transition-all duration-500 text-white text-[14px] text-subparagraph leading-[140%]  group-hover:border-b-[1px] border-white   group-hover:border-white">
                                    Privacy
                                </span>
                            </p>
                            </button>
                        </div>
                    </div>
                </div>
            </footer>
            <PrivacyPolicyPopup openPopup={openPopup} closePopup={handleClosePopup} />
        </>
    )
}

export default Footer
