import React from 'react'
import Button from '../ui/Button.tsx'
import useFlickerEffect from '../../hooks/useFlickerEffect.ts';

const Getademo = () => {
    const activeClass = useFlickerEffect();
    return (
        <>
            <div className={`relative
                 after:absolute 
                 after:top-[-115%] 
                 after:left-0
                 after:bg-[url('../src/assets/images/lefteffect.png')]
                 after:bg-no-repeat
                 after:max-w-[400px]
                 after:w-full
                 after:h-[2000px]
                 ${activeClass && 'after:animate-flicker'}
                 after:hidden
                 after:xl:block
            `}>
                <div className='max-w-[824px] w-full mx-auto px-5' >
                    <div className={`py-[12px] flex flex-col justify-center md:py-[19px] md:px-[41px] px-[21px]
                 relative 
                 after:absolute 
                 after:left-0
                 after:h-[54px]
                 after:w-[23px]
                 after:bottom-[-13px]
                 after:bg-[url('../src/assets/icons/arrowlefticon.svg')]
                 before:top-[0px]
                 before:right-0
                 before:absolute
                 before:bg-[url('../src/assets/icons/leftarrow.svg')]
                 before:h-[52px]
                 before:w-[23px]
                 `}>
                        <div className=" pb-8  text-center ">
                            <h4 className='font-gothicproLight md:text-[22px] text-base text-[#C9C9C9] md:pb-2 pb-1 leading-[32.78px]  ' >At the end of the day, only one question matters:</h4>
                            <h2 className='text-white text-[20px] text-nowrap sm:text-3xl md:text-[50px] font-gothicproMedium leading-[120%]  '>CAN YOU BE <span className='text-[#2972D7] '>HACKED</span>?</h2>
                        </div>
                        {/* <div className='w-full mx-auto max-w-[177px] md:max-w-[177px]' >
                            <Button href='#contactsection' varient='solid' buttonClass='mt-6  px-[19px] py-3 text-[19px] font-bold leading-4 px-[30px] py-4' >Get a demo</Button>
                        </div> */}
                        <a href="#contactsection" className='flex justify-center '>
                            <Button varient="solid" buttonClass="px-[22px] md:pt-[17px] md:pb-[15px] pl-[30px] pr-[34px] font-bold text-nowrap leading-4">
                                Get a demo
                            </Button>
                        </a>
                    </div>
                </div >
            </div >
        </>
    )
}

export default Getademo