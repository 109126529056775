import React from 'react'
import bannertext from "../../assets/images/BestDefenseOffense.gif"
import Button from '../ui/Button.tsx'
import useFlickerEffect from '../../hooks/useFlickerEffect.ts';

const Banner = () => {
    const activeClass = useFlickerEffect();
    // const videoRef = useRef<HTMLVideoElement>(null);

    return (
        <>
            <section id='banner' className={` relative 
                xl:before:absolute 
                xl:before:right-0
                 before:bg-right
                  xl:before:bg-[url('../src/assets/images/righteffeect3.png')]  
                  xl:before:top-[600px] 
                  xl:before:z-10
                   xl:before:max-w-[555px]
                    xl:before:w-full 
                    xl:before:h-[1570px]
                     xl:before:bg-no-repeat 
                     ${activeClass && 'before:animate-flicker'}
                      `}>
                <div className="bg-[url('../src/assets/images/newbgbanner.png')] bg-no-repeat bg-center bg-cover w-full h-full pb-[20px] xl:pb-[75px] " >
                    <div className="container relative after:absolute after:right-[40px] before:left-5 md:before:left-[40px] after:h-[54px] after:w-[23px] before:absolute before:bg-[url('../src/assets/icons/leftarrow.svg')] after:bottom-0 after:bg-[url('../src/assets/icons/rightarrow.svg')] before:h-[52px] before:w-[23px]  pb-8 pt-[47px]">
                        <div className='relative flex justify-center items-center h-full pt-[37px] '>
                            <div className="  max-w-[998px] w-full before:bg-[url('../src/assets/images/bannerStar.png')] before:animate-spin-sclaeUp before:bg-no-repeat before:absolute before:top-[-130px] max-[1170px]:before:left-[60px] before:left-0 before:h-[45px] after:bg-contain before:w-[45px]  mt-[110px] mb-[122px] relative  after:bottom-[-125px] after:absolute max-[1170px]:after:right-[60px] after:right-0  after:bg-[url('../src/assets/images/bannerStar.png')] after:animate-spin-sclaeUp after:bg-no-repeat after:w-[45px] after:h-[45px]" >
                                <div className="text-center z-20 relative before:top-[-70.19px] before:absolute  xl:before:right-[-53.19px] before:right-[0px] before:bg-[url('../src/assets/icons/righticon.svg')] before:h-[22px] before:w-[22px] after:bg-[url('../src/assets/icons/lefticon.svg')] after:w-[22px] after:h-[22px] after:absolute after:left-0  after:bottom-[-35px]  md:after:bottom-[-55px] lg:after:bottom-[-53.79px] max-w-[1067px] mx-auto" >
                                    <img className='xl:object-none object-contain max-w-[961px] w-full mx-auto xl:h-[179px] ' src={bannertext} alt="bannertext" />
                                    {/* <video className=' max-w-[961px] w-full mx-auto xl:h-[179px] pt-7 md:pt-0 ' ref={videoRef} src={thedefense} autoPlay loop muted /> */}
                                    <h2 className='font-inter text-body_text py-8 text-[21px] leading-[29.37px]' >Our platform mimics real-life adversaries</h2>
                                    {/* <div className='pt-[20px] md:pt-0 inline' >
                                        <Button href='#contactsection' varient="solid" buttonClass="px-[22px] md:pt-[17px] md:pb-[15px] pl-[30px] pr-[34px]  font-bold text-nowrap leading-4">
                                            Book A live demo
                                        </Button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner