// useFlickerEffect.ts
import { useState, useEffect } from 'react';

const useFlickerEffect = (flickerDuration: number = 5000, intervalDuration: number = 12000) => {
  const [activeClass, setActiveClass] = useState(false);

  const triggerFlicker = () => {
    setActiveClass(true);

    setTimeout(() => {
      setActiveClass(false);
    }, flickerDuration);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      triggerFlicker();
    }, intervalDuration);

    return () => clearInterval(interval);
  }, [flickerDuration, intervalDuration]);

  return activeClass;
};

export default useFlickerEffect;
