
import React from 'react'
import alonAhoron from "../../assets/images/AlonAhoron.png"
import EinavPincu from "../../assets/images/EinavPincu.png"
import OzMoyal from "../../assets/images/OzMoyal.png"
import ShayGoldberg from "../../assets/images/ShayGoldberg.png"
import inlikdin from "../../assets/icons/inlikdin.svg"
import useFlickerEffect from '../../hooks/useFlickerEffect.ts'

const About = () => {
    const activeClass = useFlickerEffect();

    return (
        <>
            <section
                id='aboutus'
                className={`md:py-[120px] py-[60px] relative
                         after:hidden 
                         after:xl:block
                         after:absolute 
                         after:max-w-[267px] 
                         after:w-full 
                         after:h-[700px] 
                         after:top-[300px] 
                         after:left-0 
                         after:bg-[url('../src/assets/images/lefteffect3.png')]
                         after:bg-no-repeat
                    ${activeClass && 'after:animate-flicker'} `}
            >
                <div className='container' >
                    <h2 style={{ textShadow: "0px 0px 20px #2972D7" }} className='pb-[30px] md:pb-[60px] text-nowrap text-[20px] sm:text-[26px] sm:text-4xl md:text-[50px] text-white text-center font-gothicproMedium leading-[52px]  drop-shadow-custom relative z-20 '>// ABOUT US</h2>
                    <div className='md:space-y-8 space-y-3 max-w-[1025px] w-full mx-auto' >
                        <h3 className='lg:text-[28px] md:text-[22px] text-base font-gothicproMedium text-[#E2EEFF]' >
                            <span className='text-[#2972D7]' >
                                ARMORY
                            </span> was founded with one goal:
                        </h3>
                        <p className='lg:text-[22px] md:text-[20px] text-base font-normal font-inter text-[#C9C9C9] ' >To flip the script on cybersecurity. While others react, we predict. While others scan, we simulate. By mimicking real-world hacker playbooks, we uncover what traditional tools miss, empowering you to eliminate threats before they escalate.</p>
                        <h3 className='lg:text-[28px] md:text-[22px] text-base font-gothicproMedium text-[#E2EEFF]' >
                            If hackers don’t wait,
                            <span className='text-[#2972D7] ml-1' >
                           why should you?
                            </span>
                        </h3>
                        <p className='lg:text-[22px] md:text-[20px] text-base font-normal font-inter text-[#C9C9C9] ' >Behind ARMORY is a team of experienced offensive hackers who have spent years thinking, acting, and operating like real attackers.</p>
                    </div>
                    <div className="
                    
                    " >
                   
                    </div>
                </div>
            </section >
        </>
    )
}

export default About
