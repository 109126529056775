import React, { useRef } from 'react'
import box1 from "../../assets/images/doesitworkbox1.png"
import box2 from "../../assets/images/doesitworkbox2.png"
import box3 from "../../assets/images/doesitworkbox3.png"
import box4 from "../../assets/images/doesitworkbox4.png"
// import analysisanim from "../../assets/video/02_Data-Analysis-anim-exactly-the-data-of-the-image-attached-in-the-email-02.mp4"
import analysisanim from "../../assets/video/02_Data Analysis anim 02.mp4"



const Deffense = () => {
    const videoRef = useRef<HTMLVideoElement>(null);
    return (
        <>
            <section id='Platform' className='pb-[60px]  min-[991px]:pb-[120px]  relative  '>
                <div className=" container bg-[url('../src/assets/images/themissionimg.png')] bg-no-repeat bg-top bg-contain">
                    <div className='max-w-[1440px] mx-auto  relative  z-20 pt-[26px]'>
                        <h2 style={{ textShadow: "0px 0px 20px #2972D7" }} className=' font-gothicproMedium md:py-[60px] py-[30px] text-nowrap leading-[120%] sm:text-[20px] text-[15px] md:text-[30px] lg:text-[40px] xl:text-[50px] text-white text-center  drop-shadow-custom '>
                        // ARMORY OFFENSIVE PLATFORM
                        </h2>
                        <div className='max-w-[1230px] w-full mx-auto md:px-5 relative z-10'>
                            {/* <img className='max-w-[937px] w-full mx-auto pb-6' src={DeffenseBg} alt="defensebg" /> */}
                            {/* <video className=' max-w-[937px] w-full mx-auto pb-6 ' ref={videoRef} src={analysisanim} autoPlay loop muted /> */}
                            <video className=' max-w-[937px] w-full mx-auto pb-6 ' src={analysisanim} ref={videoRef} autoPlay loop muted />
                            <div className='grid grid-cols-1 gap-3 md:gap-[15px] lg:gap-[35px] justify-center items-center place-items-center md:grid-cols-2 ' >
                                <div className='relative  max-w-[564px] w-full   flex flex-col items-center' >
                                    <img className='absolute w-full h-full bg-black/65 ' src={box1} alt="whiterborder" />
                                    <div className='pt-[40px] px-7 pb-[40px]' >
                                        <div className='flex items-center gap-x-2 pb-2 md:pb-0 '>
                                            <h2 style={{ textShadow: "0px 0px 20px #2972D7" }} className='font-gothicproMedium font-medium md:text-[80px] text-[60px] leading-[120%] text-white relative z-10 drop-shadow-custom'>1</h2>
                                            <p className='leading-[100%] font-gothicproMedium font-normal text-[20px] md:text-[22px] lg:text-[28px]  text-white relative z-10'>Unmatched Attack Surface Coverage</p>
                                        </div>
                                        <p className=' px-2 font-inter leading-[140%] font-normal text-base sm:text-[21px] text-start text-body_text z-10 relative '>Zero client installation, just type in your domains into the platform and let it work for you.</p>
                                    </div>
                                </div>

                                <div className='relative  max-w-[564px] w-full   flex flex-col items-center' >
                                    <img className='absolute w-full h-full bg-black/65 ' src={box3} alt="whiterborder" />
                                    <div className='pt-[31px] pl-[27px] pr-5 pb-[17px]' >
                                        <div className='flex items-center gap-x-2 pb-2 md:pb-0 '>
                                            <h2 style={{ textShadow: "0px 0px 20px #2972D7" }} className='font-gothicproMedium font-normal md:text-[80px] text-[60px] leading-[120%]  text-white relative z-10 drop-shadow-custom'>2</h2>
                                            <p className=' leading-[100%] font-gothicproMedium font-normal text-[20px] md:text-[22px] lg:text-[28px] text-white relative z-10'>AI-Driven Actions</p>
                                        </div>
                                        <p className='pl-4 px-6 font-inter leading-[140%] font-normal text-base sm:text-[21px]  text-start text-body_text z-10 relative '>Harnessing Al and machine learning, constantly adjusting and learning new cutting-edge processes.</p>
                                    </div>
                                </div>


                                <div className='relative max-w-[564px] w-full  sm:pt-0  flex flex-col items-center' >
                                    <img className='absolute  w-full h-full bg-black/65 ' src={box2} alt="whiterborder" />
                                    <div className='pt-[25px] px-7 pb-[21.4px]' >
                                        <div className='flex items-center gap-x-2 pb-2 md:pb-0 '>
                                            <h2 style={{ textShadow: "0px 0px 20px #2972D7" }} className='font-gothicproMedium font-normal md:text-[80px] text-[60px]  leading-[120%]  text-white relative z-10 drop-shadow-custom'>3</h2>
                                            <p className='font-gothicproMedium font-normal text-[20px] md:text-[22px] lg:text-[28px] leading-[100%] text-white relative z-10 max-w-[420px] w-full'>Beyond Alerts: <br /> Adversaries Simulation</p>
                                        </div>
                                        <p className='font-normal px-2 leading-[140%] font-inter text-base sm:text-[21px] text-start text-body_text z-10 relative '>Armory platform has real hack-books by Al driven machines to challenge the defenses of the entire organization, structure, and run real hacking scenarios on your company, same as adversaries would.</p>
                                    </div>
                                </div>


                                <div className='relative max-w-[564px] w-full flex flex-col items-center'>
                                    <img className='absolute  w-full h-full bg-black/65 ' src={box4} alt="whiterborder" />
                                    <div className='pt-[35px] pb-[38px] px-7'>
                                        <div className='flex items-center gap-x-2 pb-2 md:pb-0 '>
                                            <h2 style={{ textShadow: "0px 0px 20px #2972D7" }} className='font-gothicproMedium font-normal md:text-[80px] text-[60px]  leading-[120%]  text-white relative z-10 drop-shadow-custom'>4</h2>
                                            <p className=' leading-[100%] font-gothicproMedium font-normal text-[20px] md:text-[22px] lg:text-[28px]  text-white relative z-10'>No more 1000 findings... Only what you need</p>
                                        </div>
                                        <p className='font-normal font-inter text-base sm:text-[21px] leading-[140%] text-start md:mt-2 text-body_text z-10 relative '>Enough with endless vulnerabilities and risk assessments. We show you exactly how hackers would breach your company. Get straight to the point deliverables with our offensive hack-books.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default Deffense