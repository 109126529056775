import React from 'react'
import career from "../../assets/images/careerimg.png"
import careerbox from "../../assets/images/box6.png"
import useFlickerEffect from '../../hooks/useFlickerEffect.ts';

const Career = () => {

    const activeClass = useFlickerEffect();

    return (
        <>
            <section
                className={`relative after:hidden after:xl:block after:absolute after:right-0 ${activeClass && 'after:animate-flicker'} after:top-[-950px] after:w-[500px] after:h-[1400px]  after:bg-[url('../src/assets/images/righteffect1.png')] after:bg-no-repeat`}
                id='careers'
            >
                <div className='container' >
                    <h2 style={{ textShadow: "0px 0px 20px #2972D7" }} className='pb-[20px] md:pb-[60px] text-nowrap text-[20px] sm:text-[26px] sm:text-4xl md:text-[50px] text-white text-center font-gothicproMedium leading-[52px]  drop-shadow-custom relative z-20 '>// CAREERS</h2>
                    <div className='space-y-8' >
                        <h3 className='xl:text-[28px] lg:text-[22px] text-base text-center font-gothicproMedium text-[#E2EEFF]' >
                            Join  <span className='text-[#2972D7]' >
                                ARMORY
                            </span>  and push the boundaries of cybersecurity
                        </h3>
                    </div>

                    <div className='flex lg:flex-row flex-col items-center justify-between gap-5  max-w-[1082px] w-full mx-auto md:pt-[48px] pt-[28px]' >
                        <img className='max-w-[370px] w-full' src={career} alt="career" />
                        <div className='relative max-w-[564px] w-full flex flex-col items-center'>
                            <img className='absolute  w-full h-full' src={careerbox} alt="whiterborder" />
                            <div className='md:py-[42px] md:px-[47px] py-4 px-5 '>
                                <div className='flex items-start flex-col md:gap-5 gap-2  '>
                                    <p className=' text-start text-base lg:text-[21px] font-inter font-normal leading-[29.4px] text-[#C9C9C9]'>Work alongside experts, tackle real-world threats, and build solutions that outsmart attackers.</p>
                                    <p className=' text-start text-base lg:text-[21px] font-inter font-normal leading-[29.4px] text-[#C9C9C9]'>To be part of the team, send your resume to:</p>
                                    <a
                                        href="mailto:hr@armorydefense.com"
                                        style={{ textShadow: "0px 0px 20px #2972D7" }}
                                        className="text-start text-nowrap text-[20px] sm:text-[15px] md:text-[28px] text-white font-gothicproMedium leading-[52px] drop-shadow-custom relative z-20"
                                    >
                                        hr@armorydefense.com
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section >
        </>
    )
}

export default Career
