import React from 'react'
import Banner from '../component/home/Banner.tsx'
import TheMission from '../component/home/TheMission.tsx'
import Global from '../component/home/Global.tsx'
import Deffense from '../component/home/Deffense.tsx'
import Strategy from '../component/home/Strategy.tsx'
import Getademo from '../component/home/Getademo.tsx'

const Home = () => {
  return (
    <>
      <Banner />
      <Deffense />
      <Getademo />
      <Global />
      <TheMission />
      <Strategy />

    </>
  )
}

export default Home