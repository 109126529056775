import React, { FC } from 'react';
import Button from './Button.tsx';

interface ContactArmoryProps { }

const ContactArmory: FC<ContactArmoryProps> = () => {


    const handleContactClick = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const recipientEmail = "sales@armorydefense.com";
        const subject = "Request for Live Demo";
        const body = `I would like to obtain more information about Armory`;
        window.location.href = `mailto:${recipientEmail}?subject=${encodeURIComponent(
            subject
        )}&body=${encodeURIComponent(body)}`;
    };


    return (
        <div className='flex flex-col gap-3 lg:gap-6 px-8 relative z-10 my-[40px]'>
            <h3 className='text-[22px] sm:text-[28px] leading-[100%] font-gothicproMedium  text-white'>
                Get your exclusive live demo and see Armory in action!
            </h3>
            <p className='text-lg leading-[140%] sm:text-[21px]  font-inter text-[#C9C9C9] max-w-[380px] w-full'>
                Discover how Armory safeguards your organization with cutting-edge technology.
            </p>

            <div className='mt-3'>
                <Button onClick={(e: any) => handleContactClick(e)}
                    type="submit"
                    varient='solid'
                    buttonClass='py-[11px] px-[18px]'
                >
                    Contact Armory
                </Button>
            </div>
        </div>
    );
};

export default ContactArmory;
