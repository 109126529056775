import React from 'react';
import WhiteCard2 from "../../assets/images/hackedouter.png";
import Hackedelipse from "../../assets/images/lefteffect2.png";
import lefteffect4 from "../../assets/images/lefteffect5.png";
import bg7 from "../../assets/images/shadowline2.png";
import RotatingCirclesWithIcons from '../ui/Animation.tsx';
import ContactArmory from '../ui/ContactArmory.tsx';
import CustomersFeddback from './CustomersFeddback.tsx';
import useFlickerEffect from '../../hooks/useFlickerEffect.ts';
import AttackAnim from "../../assets/images/AttackAnimFinal.gif"
import About from './About.tsx';
import Career from './career.tsx';


const Strategy = () => {
    const activeClass = useFlickerEffect();
    return (
        <>
            <section id='strategysection' className='relative'>
                <img src={Hackedelipse} className={` hidden xl:block absolute left-0 top-[350px] ${activeClass && "animate-flicker"}`} alt="elipse" />
                <img src={lefteffect4} className={` hidden xl:block absolute left-0 bottom-[-300px] ${activeClass && "animate-flicker"}`} alt="elipse" />
                <div className='relative' >
                    <div className='container' >
                        <div>
                            <div className="relative text-nowrap after:absolute after:bg-[url('../src/assets/images/strategylight.png')] after:top-0 after:left-0 after:right-0 after:bottom-0 z-30 after:bg-contain after:bg-top after:bg-no-repeat h-full ">
                                <h2 style={{ textShadow: "0px 0px 20px #2972D7" }} className=' text-white font-gothicproMedium text-[20px] sm:text-4xl md:text-[50px] text-center drop-shadow-custom pt-10 md:pt-20  relative z-20 '>// DEFENSE STRATEGY</h2>
                                <div className='xl:relative -top-20'>
                                    <RotatingCirclesWithIcons />
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className={`hidden lg:block absolute top-[10%] ${activeClass && 'animate-flicker'} min-[1324px]:top-[0%] right-0`} src={bg7} alt="flickerimg" />
                </div>
                <CustomersFeddback />
                <About />
                <Career />
                <div className="relative after:absolute after:hidden after:lg:block after:top-[-93%] after:right-0 after:w-[500px] after:h-[800px] after:bg-no-repeat after:bg-[url('../src/assets/images/bgeffectcircleshadow.png')] " >
                    <div id='contactsection' className='container'>
                        <div className="flex flex-col gap-7 md:gap-[60px]  lg:mt-[120px] mt-[60px] md:mt-[80px]  ">
                            <div className='' >
                                <p className='text-[#E2EEFF] md:pb-4 pb-2  text-center xl:text-[28px] lg:text-[20px] sm:text-base text-[12px] font-medium font-gothicproMedium leading-[120%] ' >At the end of the day, only one question matters:</p>
                                <h3 className='drop-shadow-custom_red text-white text-[20px] sm:text-4xl md:text-[50px] md:leading-[52.34px] font-gothicproMedium text-center ' style={{ textShadow: "0px 0px 20px #FF0000" }} >// CAN YOU BE HACKED?</h3>
                            </div>
                            <div className=' grid place-items-center md:grid-cols-2 gap-10 items-center w-full relative z-10' >
                                <div className='relative max-w-[465px] w-full flex flex-col items-center'>
                                    <img className='absolute top-0 bottom-10 left-0 right-0 w-full h-full bg-black/65 ' src={WhiteCard2} alt="whiterborder" />
                                    <ContactArmory />
                                </div>
                                <img className='max-w-[603px] w-full' src={AttackAnim} alt="hacked" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Strategy