"use client"

import React from 'react'
import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Footer from './component/common/Footer.tsx';
import Header from './component/common/Header.tsx';
import Home from './pages/Home.tsx';

function App() {
  return (
    <>
      <BrowserRouter>
        {/* <div className="bg-[url('../src/assets/images/homebg.png')] h-full repeat bg-center bg-cover" > */}
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
        {/* </div> */}
      </BrowserRouter>
    </>
  )
}

export default App;
