import React, { useRef } from 'react'
// import Border from "../../assets/images/border.png"
// import Button from '../ui/Button.tsx'
// import useFlickerEffect from '../../hooks/useFlickerEffect.ts'
// import mapvideo from "../../assets/video/03_GWR_V4_FINAL.mp4"
import mapvideo from "../../assets/images/03_GWR_V4_FINAL.gif"
import box7 from "../../assets/images/box7.png"
// import mapimage from "../../assets/images/mapimage2.5.png"

const Global = () => {
    // const activeClass = useFlickerEffect();
    const videoRef = useRef<HTMLVideoElement>(null);
    return (
        <>

            {/* after:hidden 
            after:xl:block
            after:absolute 
            after:max-w-[267px] 
            after:w-full 
            after:h-[350px] 
            after:top-[0%] 
            after:right-0 
            after:bg-[url('../src/assets/images/angleright.png')]
            ${activeClass && 'after:animate-flicker'}


              before:hidden 
            before:xl:block
            before:absolute 
            before:max-w-[500px] 
            before:w-full 
            before:h-[800px] 
            before:top-[-30%] 
            before:right-0
            before:bg-[url('../src/assets/images/bgeffectcircleshadow.png')]
            ${activeClass && 'before:animate-flicker'}
             */}



            <section id='WarRooms' className={`relative z-40
            pb-10 sm:pb-20  lg:pb-[137.95px]  mt-[60px] md:mt-[120px]

            
          
            `}>
                <div className='max-w-[1200px] w-full mx-auto px-5'>
                    <h2 style={{ textShadow: "0px 0px 20px #2972D7" }} className='sm:text-[26px] text-[20px] sm:text-4xl md:text-[50px] text-white text-center font-gothicproMedium leading-[52px]  drop-shadow-custom '>    // GLOBAL WAR ROOMS</h2>
                    <div className='mt-5 md:mt-[45px] relative z-40 '>
                        <div className='max-w-[1060px] w-full' >
                            {/* <video className='w-full' ref={videoRef} src={mapvideo} autoPlay loop muted /> */}
                            <img className='w-full' src={mapvideo} alt="mapvideo" />
                        </div>
                        <div className='flex justify-end'>
                            <div className='relative max-w-[934px] w-full flex flex-col items-center' >
                                <img className='absolute w-full h-full bg-black/65' src={box7} alt="whiterborder" />
                                <div className='pt-[41px] px-8 pb-[27px]' >
                                    <p className='lg:text-[28px] md:text-[22px] text-base font-gothicproMedium text-white z-10 relative md:pb-6 pb-3' >Your Protection, Our Priority</p>
                                    <p className='font-inter leading-[140%] font-normal text-base sm:text-[21px] text-start text-[#C9C9C9] z-10 relative '>At the heart of Armory’s operations are three global war-rooms located in Israel, Mexico, and the UK. Using a “Follow The Sun” model to ensure around-the-clock protection, elite hackers pilot Armory’s systems, relentlessly testing and challenging defenses to stay ahead of evolving cyber threats.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>)
}

export default Global
