import React from 'react'
// import warroom from "../../assets/images/warRoom.png"
import warroom from "../../assets/images/04_EBC Glitch_FINAL.gif"
import useFlickerEffect from '../../hooks/useFlickerEffect.ts';

const TheMission = () => {

    const activeClass = useFlickerEffect();

    return (
        <>
            <section id='EBC' className={`
            relative
            after:hidden after:xl:block
                after:absolute after:right-0 ${activeClass && 'after:animate-flicker'} after:top-[-250px] after:w-[500px] after:h-[1400px]  after:bg-[url('../src/assets/images/righteffeect2.png')] after:bg-no-repeat            
             z-30 px-5`}>
                <div className='max-w-[1440px] w-full mx-auto' >
                    <div className="   md:pb-[120px] pb-[60px]  " >
                        <h2 style={{ textShadow: "0px 0px 20px #2972D7" }} className='text-[20px] sm:text-4xl md:text-[50px] md:pb-[60px] pb-[30px] text-white text-center font-gothicproMedium md:leading-[52px]  drop-shadow-custom '>
                        // CYBER RESILIENCE MEETS <br /> EXECUTIVE STRATEGY
                        </h2>
                        <div className='flex relative z-30 min-[1126px]:flex-row items-center gap-5 justify-between flex-col max-w-[1160px] mx-auto  w-full md:px-[15px]' >
                            <img className='min-[1126px]:max-w-[694px] xl:max-h-[414px] w-full ' src={warroom} alt="warroomimage" />
                            <div>
                                <div className='flex flex-col gap-5 md:gap-[42px]  min-[1126px]:max-w-[380px] w-full items-start' >
                                    <p className="text-[#C9C9C9] text-base md:text-[21px] font-inter leading-[140%] font-normal">
                                        The Armory War Room is an advanced cybersecurity hub where ethical hackers leverage the Armory platform to conduct
                                        <span className='text-[21px] font-bold font-inter text-white text-base md:text-[21px]' > real-world attack simulations </span> and <span className='text-[21px] font-bold font-inter text-white text-base md:text-[21px]' >vulnerability assessments.</span>
                                    </p>
                                    <p className="text-[#C9C9C9] text-base md:text-[21px] font-inter leading-[140%] font-normal">
                                        Executives can experience <span className='text-[21px] font-bold font-inter text-white text-base md:text-[21px]'> live attack scenarios </span>, gain hands-on training in  <span className='text-[21px] font-bold font-inter text-white text-base md:text-[21px]'>cyber risk management</span>, and understand their own <span className='text-[21px] font-bold font-inter text-white text-base md:text-[21px]'> digital risk exposure </span>. This immersive environment provides leaders with actionable insights to strengthen their defenses and make informed security decisions in real time.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TheMission