import React, { useEffect, useState } from "react";
const PrivacyPolicyPopup = ({ openPopup, closePopup }) => {
    const [isVisible, setIsVisible] = useState(false); // State for triggering zoom effect
    useEffect(() => {
        if (openPopup) {
            document.body.style.overflow = "hidden"; // Prevent background scrolling
            setIsVisible(true); // Trigger zoom effect when popup opens
        } else {
            setIsVisible(false); // Start closing the popup with zoom effect
            document.body.style.overflow = ""; // Restore scrolling
        }
        return () => {
            document.body.style.overflow = ""; // Cleanup on unmount
        };
    }, [openPopup]);
    if (!openPopup && !isVisible) return null; // Prevent rendering when popup is both closed and not visible
    const Images = [
        "privacy_page-0001.jpg",
        "privacy_page-0002.jpg",
        "privacy_page-0003.jpg",
        "privacy_page-0004.jpg",
        "privacy_page-0005.jpg",
        "privacy_page-0006.jpg",
        "privacy_page-0007.jpg",
        "privacy_page-0008.jpg",
        "privacy_page-0009.jpg",
        "privacy_page-0010.jpg",
        "privacy_page-0011.jpg",
        "privacy_page-0012.jpg",
        "privacy_page-0013.jpg",
    ];
    return (
        <div
            className="fixed inset-0 flex items-center justify-center z-[100]"
            onClick={closePopup} // Clicking the overlay closes the popup
        >
            {/* Background overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
            {/* Popup container with zoom-in effect */}
            <div
                className={`relative bg-[#041122] mx-3 rounded-md p-3 shadow-lg w-[700px] h-[80vh] flex flex-col border-2 border-gray-500/30 transform transition-all duration-200 ${isVisible
                    ? "scale-100 opacity-100"
                    : "scale-90 opacity-0 pointer-events-none"
                    }`}
                onClick={(e) => e.stopPropagation()} // Prevent click event from closing popup inside
            >
                {/* Header with Close button */}
                <h2 className="text-xl font-semibold mb-4 flex items-center justify-between text-white capitalize">
                    <span className="drop-shadow-[0px_0px_5px_red]">
                        Privacy Policy
                    </span>
                    <button onClick={closePopup} className="text-[#2972D7] ">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-7 w-7"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </h2>
                {/* Scrollable Image Container */}
                <div className="flex-1 overflow-y-scroll space-y-2 sm:space-y-4 custom-scroll ">
                    {Images?.map((item, index) => (
                        <img
                            key={index}
                            src={require(`../../assets/images/${item}`)}
                            alt={`privacy-page-${index + 1}`}
                            className="w-full scrollbar-hidden"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
export default PrivacyPolicyPopup;






